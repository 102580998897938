import { makeRequest } from './make-request';
import { fetchWithPromise } from './fetch-with-cache';

export async function newBucketImage(file, type) {
  const formData = new FormData();
  formData.append('file', file, type || undefined);
  return makeRequest('post', 'image-bucket', formData, undefined, true);
}

export async function newDncFile(file, type, crmSettingsId) {
  const formData = new FormData();
  formData.append('file', file, type || undefined);
  return makeRequest('post', 'dnc-upload/large', formData, crmSettingsId, true);
}

export async function newMediaFile(payload) {
  return makeRequest('post', 'media-files', payload);
}

export async function mediaFilesForOrg(orgId) {
  const key = `media-files-for-org-${orgId}`;
  const params = { orgId: orgId || null };
  const { data, errorMessage } = await fetchWithPromise(key, `media-files/for-org`, params);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function deleteMediaFile(id) {
  const { data, errorMessage } = await makeRequest('delete', 'media-files', undefined, id);
  return { data, errorMessage };
}
