export function isEmail(email) {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
}

export function emailsToArray(emails) {
  return (emails || '')
    .split(',')
    .map((e) => e.trim().toLowerCase())
    .filter((x) => !!x)
    .filter((x) => isEmail(x));
}
