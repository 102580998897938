import { Notification, toast } from '../index';
import React from 'react';

const toastSuccess = (title, message, duration) => {
  toast.push(<Notification title={title} message={message} duration={duration} type="success" />, {
    placement: 'top-center'
  });
};

export default toastSuccess;
