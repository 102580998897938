import { ADMIN, SUPER_USER, USER, USER_SMS, USER_SMS_LIMITED, WEB_DEVELOPER } from 'constants/roles.constant';
import { PermissionCode } from '../constants/permission.enum';

export function userToAuthority(user, organizationId) {
  if (user.isSuperUser) {
    return [SUPER_USER];
  }
  const authority = [USER];
  const isAdmin = (user?.permissions || []).some(
    (p) => p.organizationId === organizationId && p.typeCode === PermissionCode.admin
  );
  if (isAdmin) {
    authority.push(ADMIN);
  }
  const isUserSms = (user?.permissions || []).some(
    (p) => p.organizationId === organizationId && p.typeCode === PermissionCode.userSms
  );
  if (isUserSms) {
    authority.push(USER_SMS);
  }
  const isUserSmsLimited = (user?.permissions || []).some(
    (p) => p.organizationId === organizationId && p.typeCode === PermissionCode.userSmsLimited
  );
  if (isUserSmsLimited) {
    authority.push(USER_SMS_LIMITED);
  }
  const isWebDeveloper = (user?.permissions || []).some(
    (p) => p.organizationId === organizationId && p.typeCode === PermissionCode.webDeveloper
  );
  if (isWebDeveloper) {
    authority.push(WEB_DEVELOPER);
  }
  return authority;
}
