import React, { useState } from 'react';
import { Button, Dialog, Tooltip } from 'components/ui';

export const QuestionDialog = ({
  width,
  warn,
  dialogIsOpen,
  onDialogClose,
  onDialogOk,
  dialogTitle,
  dialogText,
  shouldCloseOnOverlayClick,
  hideNo,
  yesButtonText
}) => {
  return (
    <Dialog
      isOpen={dialogIsOpen}
      onClose={onDialogClose}
      onRequestClose={onDialogClose}
      width={width}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}>
      <h5 className="mb-4">{dialogTitle}</h5>
      <p>{dialogText}</p>
      <div className="text-right mt-6">
        {hideNo ? (
          <></>
        ) : (
          <Button className="ltr:mr-2 rtl:ml-2" onClick={onDialogClose}>
            No
          </Button>
        )}
        <Button color={warn ? 'red-600' : ''} variant="solid" onClick={onDialogOk}>
          {yesButtonText || 'Yes'}
        </Button>
      </div>
    </Dialog>
  );
};

const QuestionDialogButton = ({
  buttonTitle,
  dialogTitle,
  dialogText,
  onYes,
  warn,
  loading,
  icon,
  tooltip,
  disabled,
  iconOnly,
  width,
  fontSize
}) => {
  const [dialogIsOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const onDialogOk = (e) => {
    setIsOpen(false);
    onYes();
  };

  const button = icon ? (
    <Tooltip title={tooltip} hide={!tooltip}>
      {iconOnly ? (
        <div style={{ fontSize }} className="cursor-pointer" onClick={() => openDialog()}>
          {icon}
        </div>
      ) : (
        <Button
          onClick={() => openDialog()}
          disabled={disabled}
          type="button"
          color={warn ? 'red-700' : ''}
          shape="circle"
          size="sm"
          variant="twoTone"
          icon={icon}
        />
      )}
    </Tooltip>
  ) : (
    <Tooltip title={tooltip} hide={!tooltip}>
      <Button
        disabled={disabled}
        loading={loading}
        type="button"
        color={warn ? 'red-700' : ''}
        variant="solid"
        onClick={() => openDialog()}>
        {buttonTitle}
      </Button>
    </Tooltip>
  );

  return (
    <span>
      {button}
      <QuestionDialog
        warn={warn}
        dialogIsOpen={dialogIsOpen}
        onDialogClose={onDialogClose}
        dialogText={dialogText}
        dialogTitle={dialogTitle}
        onDialogOk={onDialogOk}
        width={width}
      />
    </span>
  );
};

export default QuestionDialogButton;
