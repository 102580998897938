import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';
import locale from './locale/localeSlice';
import organization from './org/org-slice';
import settings from './settings/settings.slice';
import refreshMenu from './refresh-menu/refresh-menu.slice';
import noLayout from './no-layout/no-layout-slice';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    locale,
    organization,
    settings,
    refreshMenu,
    noLayout,
    ...asyncReducers
  });
  return combinedReducer(state, action);
};

export default rootReducer;
