export function toDollarString(value, nofix = false) {
  value = Number(value);
  if (value < 0.01) {
    return '$' + Number(value).toFixed(4);
  }
  if (value < 0.1) {
    return '$' + Number(value).toFixed(3);
  }
  if (value >= 80 || nofix) {
    value = Math.floor(Number(value));
    return '$' + value.toLocaleString();
  }
  return '$' + Number(value).toFixed(2);
}
