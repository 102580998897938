import { PERSIST_STORE_NAME } from '../constants/app.constant';
import deepParseJson from './deepParseJson';
import store from '../store';

export function getAccessToken() {
  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
  const persistData = deepParseJson(rawPersistData);

  let accessToken = persistData.auth.session.token;

  if (!accessToken) {
    const { auth } = store.getState();
    accessToken = auth.session.token;
  }
  return accessToken;
}
