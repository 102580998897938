import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import { apiSignIn, apiSignUp } from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';
import { userToAuthority } from '../user-to-authority';
import { useEffect, useRef } from 'react';
import { makeRequest } from '../../services/make-request';
import { toastError } from '../../components/ui';
import { delay } from '../delay';
import { ADMIN, USER } from '../../constants/roles.constant';

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { token, signedIn } = useSelector((state) => state.auth.session);
  const refreshTokenInterval = useRef(null);
  const org = useSelector((state) => state.organization.org);

  useEffect(() => {
    if (!org) {
      return;
    }
    refreshToken();
  }, [org]);

  const refreshToken = async () => {
    try {
      const { data, errorMessage } = await makeRequest('post', 'auth/refresh', {});
      if (errorMessage) {
        toastError(errorMessage);
      }
      if (data) {
        const { token, user } = data;
        dispatch(onSignInSuccess({ token, user }));
        if (data.user) {
          const userInfo = {
            avatar: '',
            userName: `${user.firstName} ${user.lastName}`,
            authority: userToAuthority(user, org?.id),
            email: user.email,
            ...data.user
          };
          dispatch(setUser(userInfo));
        }
        return {
          status: 'success',
          message: ''
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      };
    }
  };

  useEffect(() => {
    // Start interval when user signs in
    if (token && signedIn) {
      // Clear previous interval
      if (refreshTokenInterval.current) {
        clearInterval(refreshTokenInterval.current);
      }
      refreshTokenInterval.current = setInterval(() => {
        refreshToken();
      }, 3600000); // 3600000ms is equivalent to 1 hour

      // Cleanup interval on sign out or component unmount
      return () => {
        if (refreshTokenInterval.current) {
          clearInterval(refreshTokenInterval.current);
        }
      };
    }
  }, [token, signedIn]);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        const { token, user } = resp.data;
        const authority = userToAuthority(user, org?.id);
        dispatch(onSignInSuccess({ token, user }));
        if (resp.data.user) {
          const userInfo = {
            avatar: '',
            userName: `${user.firstName} ${user.lastName}`,
            authority: authority,
            email: user.email,
            ...resp.data.user
          };
          dispatch(setUser(userInfo));
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: 'success',
          message: ''
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: '',
                userName: 'Anonymous',
                authority: [USER],
                email: ''
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: 'success',
          message: ''
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      };
    }
  };

  const handleSignOut = async () => {
    await delay(100);
    dispatch(onSignOutSuccess());
    await delay(100);
    dispatch(setUser(initialState));
    await delay(100);
    navigate(appConfig.unAuthenticatedEntryPath);
    await delay(100);
    if (refreshTokenInterval.current) {
      await delay(100);
      clearInterval(refreshTokenInterval.current);
      await delay(100);
      refreshTokenInterval.current = null;
    }
  };

  const signOut = async () => {
    // await apiSignOut();
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
    refreshToken
  };
}

export default useAuth;
