import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import history from './history';
import mockServer from './mock';
import appConfig from 'configs/app.config';
import './locales';
import * as Fathom from 'fathom-client';

const environment = process.env.REACT_APP_NODE_ENV;

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
  mockServer({ environment });
}

if (environment === 'development') {
  const metaRobots = document.createElement('meta');
  metaRobots.name = 'robots';
  metaRobots.content = 'noindex,nofollow';
  document.getElementsByTagName('head')[0].appendChild(metaRobots);
}

const FathomTracker = () => {
  const location = useLocation();

  useEffect(() => {
    Fathom.load('PJLAFPXC', {
      url: 'https://cdn.usefathom.com/script.js',
      includedDomains: ['admin.heavyset.tech']
    });

    Fathom.trackPageview();
  }, [location]); // Triggered when route changes

  return null; // Component does not render anything
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <FathomTracker />
          <Theme>
            <Layout />
          </Theme>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
