import { fetchWithPromise } from './fetch-with-cache';

export async function getCountries(limit, search) {
  const key = `get-countries-${limit},${search}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'countries', {
    limit,
    search
  });
  return { records: data, errorMessage };
}

export async function getUsStates(limit, search) {
  const key = `get-us-states-${limit},${search}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'countries/us-states', {
    limit,
    search
  });
  return { records: data, errorMessage };
}

export async function getUsCities(limit, search) {
  const key = `get-us-cities-${limit},${search}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'zip-codes/cities', {
    limit,
    search
  });
  return { records: data, errorMessage };
}
