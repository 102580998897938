import { PhoneValidator } from 'utils';
import { makeRequest } from './make-request';
import { fetchWithPromise } from './fetch-with-cache';

export async function phoneCleanup(crmSettingsId, phone) {
  phone = PhoneValidator.normalize(phone);
  const { data, errorMessage } = await makeRequest('post', 'compliance/phone-cleanup', { phone, crmSettingsId });
  return { data, errorMessage };
}

export async function getDncUploadData(crmSettingsId, limit, page = 0) {
  const key = `get-dnc-upload-data-${limit},${page}${crmSettingsId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'dnc-upload/recent', {
    crmSettingsId,
    limit,
    page
  });
  return data ? { records: data.records, totalCount: data.totalCount } : { errorMessage };
}
