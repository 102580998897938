import { Notification, toast } from '../index';
import React from 'react';

let currentToast = undefined;

export const toastError = (title, message) => {
  if ((message || '').includes('Unauthorized') || (title || '').includes('Unauthorized')) {
    toastWarn('Login Expired, Please Login');
    return;
  }
  toast.push(
    <Notification title={title} type="danger" duration={8000}>
      {message}
    </Notification>,
    {
      placement: 'top-center'
    }
  );
};

export const toastWarn = (title, message, duration = 8000) => {
  if (currentToast) {
    return;
  }
  currentToast = true;
  setTimeout(() => {
    currentToast = false;
  }, 5000);
  toast.push(
    <Notification title={title} type="warning" duration={duration}>
      {message}
    </Notification>,
    {
      placement: 'top-center'
    }
  );
};

export default toastError;
