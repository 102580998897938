import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmSettingsList } from '../../services';
import { getHasTimeBlocks } from '../../services/crm-query.service';

export const fetchSettingsThunk = createAsyncThunk('settings/fetchSettings', async (org, thunkAPI) => {
  if (!org) {
    return [];
  }
  const { records, totalCount } = await getCrmSettingsList(100, undefined, 0, undefined, org.id);
  const list = records || [];
  for (const config of list) {
    const { data } = await getHasTimeBlocks(config.id);
    config.hasTimeBlocks = !!data?.hasTimeBlocks;
  }
  return list;
});
