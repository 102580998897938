import { isEmail } from './emails-to-array';
import { PhoneValidator } from './phone-validator';

export const textToWords = (text) => {
  const words = (text || '')
    .replace(/[,;"']+/g, ' ')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ');
  return words;
};

export const toCommaEmailPhoneList = (text) => {
  const words = textToWords(text)
    .map((t) => {
      t = (t || '').toLowerCase();
      if (isEmail(t)) {
        return t;
      }
      if (PhoneValidator.isValid(t)) {
        return PhoneValidator.normalize(t);
      }
      return undefined;
    })
    .filter((t) => !!t)
    .join(',');
  return words;
};
