// refreshMenuSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  refreshMenu: 0
};

export const refreshMenuSlice = createSlice({
  name: 'refreshMenu',
  initialState,
  reducers: {
    incrementRefreshMenu: (state) => {
      state.refreshMenu += 1;
    },
    setRefreshMenuValue: (state, action) => {
      state.refreshMenu = action.payload;
    }
  }
});

export const { incrementRefreshMenu, setRefreshMenuValue } = refreshMenuSlice.actions;

export default refreshMenuSlice.reducer;
