import { fetchWithCache, fetchWithPromise } from './fetch-with-cache';
import { makeRequest } from './make-request';

export async function createCrmSettings(newSettings) {
  const { data, errorMessage } = await makeRequest('post', 'crm/settings', newSettings);
  return { crmSettings: data, errorMessage };
}

export async function updateConfig(update, id) {
  const { data, errorMessage } = await makeRequest('put', 'crm/settings', update, id);
  return data || { errorMessage };
}

export async function deleteConfig(id) {
  const { data, errorMessage } = await makeRequest('delete', 'crm/settings', undefined, id);
  return data || { errorMessage };
}

export async function getCrmConfig(id) {
  const { data, errorMessage } = await fetchWithPromise('get-crm-config', `crm/settings/${id}`);
  return data || { errorMessage };
}

export async function podiumRefresh(id) {
  const { data, errorMessage } = await makeRequest(`post`, `podium/refresh/${id}`, {});
  return data || { errorMessage };
}

export async function getCrmTypes() {
  const { data, errorMessage } = await fetchWithCache('get-crm-types', 'crm/types');
  return { types: data, errorMessage };
}

export async function getCrmSettingsList(limit, name, page = 0, orderBy, orgId) {
  const key = `get-crm-settings-${limit},${page},${name},${orderBy},${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'crm/settings', {
    limit,
    page,
    name,
    orderBy,
    orgId
  });
  return data
    ? {
        records: data.crmSettings,
        totalCount: data.totalCount
      }
    : { errorMessage };
}

export async function getApiTokenList(limit, name, page = 0, orderBy, orgId) {
  const key = `get-api-tokens-${limit},${page},${name},${orderBy},${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'appointment/page/tokens', {
    limit,
    page,
    name,
    orgId
  });
  return data
    ? {
        records: [{ id: undefined }, ...data.rows],
        totalCount: data.totalCount
      }
    : { errorMessage };
}

export async function getUnexpectedMarkets(settingId) {
  if (!settingId) {
    return { markets: [] };
  }
  const key = `get-unexpected-markets-${settingId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'improveit-query/unexpected-markets', {
    settingId
  });
  const markets = data.map((m) => {
    if (!m) {
      return {
        value: 'null',
        label: 'null'
      };
    }
    return {
      value: m,
      label: m
    };
  });
  return { markets, errorMessage };
}

export async function getImproveitLeadSourceData(settingId, market) {
  if (!settingId) {
    return [];
  }
  const key = `get-improveit-lead-source-market-data-${settingId}-${market}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'improveit-query/lead-sources', {
    settingId,
    market
  });
  return { records: data, errorMessage };
}

export async function getCrmMarkets(search, settingId, code) {
  if (!settingId) {
    return { records: [] };
  }
  const key = `get-crm-markets-${search},${settingId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'crm-query/markets', {
    search,
    settingId
  });
  let name = 'All Markets';
  const records = [{ id: 'all', name }, ...(data || [])];
  return { records, errorMessage };
}

export async function getCrmQueues(search, settingId, code) {
  if (!settingId) {
    return { records: [] };
  }
  const key = `get-crm-queues-${search},${settingId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'crm-query/queues', {
    search,
    settingId
  });
  let name = 'N/A';
  const records = [{ id: 'all', name }, ...(data || [])];
  return { records, errorMessage };
}

export async function getPodiumLocations(settingId, plain) {
  if (!settingId) {
    return { records: [] };
  }
  const key = `get-podium-locations-${settingId}`;
  const { data, errorMessage } = await fetchWithPromise(key, `podium/locations/${settingId}`);
  const records = (data || []).map((d) => {
    return {
      id: d.podiumPhoneNumber,
      name: d.name
    };
  });
  if (plain) {
    return { records, errorMessage };
  }
  return { records: [{ id: 'default', name: 'Use Default' }, ...records], errorMessage };
}

export async function getExampleProductsDispositions(crmSettingsId) {
  if (!crmSettingsId) {
    return { data: { products: [], dispositions: [] } };
  }
  const key = `get-xample-prod-disp-${crmSettingsId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'crm-query/example-dispositions-products', {
    crmSettingsId
  });
  return { data, errorMessage };
}
