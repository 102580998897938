import { fetchWithPromise } from './fetch-with-cache';
import { currentTimeZone } from '../utils/format-yyymmdd';

export async function queryPageAuditCounts(orgId, type, org) {
  if (!orgId) {
    return { data: [] };
  }
  const key = `query-page-audit-counts-${orgId}-${type}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'audit/year-counts', {
    orgId,
    type,
    timeZone: currentTimeZone(org)
  });
  return { data, errorMessage };
}

export async function getSources(limit, search, page = 0, orderBy = undefined, orgId) {
  const key = `get-sources-${limit},${page},${search},${orderBy},${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'audit/sources', {
    limit,
    page,
    search,
    orderBy,
    orgId
  });
  return data ? { records: data, totalCount: limit } : { errorMessage };
}

export async function getSourceTypes(limit, search, page = 0, orderBy = undefined, orgId) {
  const key = `get-source-types-${limit},${page},${search},${orderBy},${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'audit/source-types', {
    limit,
    page,
    search,
    orderBy,
    orgId
  });
  return data ? { records: data, totalCount: limit } : { errorMessage };
}

// orderBy = 'email' | 'createdAt'
export async function getPageViewAuditList(
  limit,
  name,
  page = 0,
  orderBy,
  orgId,
  source,
  sourceType,
  leadId,
  prospectId,
  city,
  stateAlpha2,
  zip,
  leadFilter,
  appointmentFilter,
  createdStart,
  createdEnd,
  phone,
  hideExisting,
  isLinkId,
  apiTokenId,
  emailFilter,
  leadAndApptFilter
) {
  const key =
    `get-page-view-audit-list-${limit},${page},${name},${orderBy},${orgId},${source},${sourceType}` +
    `,${leadId},${prospectId},${city},${stateAlpha2},${zip},${leadFilter},${appointmentFilter},${createdStart}` +
    `,${createdEnd},${phone},${apiTokenId},${isLinkId},${emailFilter}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'audit/pages', {
    limit,
    page,
    name,
    orderBy,
    orgId,
    source,
    sourceType,
    leadId,
    prospectId,
    city,
    stateAlpha2,
    zip,
    leadFilter,
    appointmentFilter,
    createdStart,
    createdEnd,
    phone,
    hideExisting,
    isLinkId,
    apiTokenId,
    emailFilter,
    leadAndApptFilter
  });
  return data ? { rows: data.rows, totalCount: data.totalCount } : { errorMessage };
}
