import { makeRequest } from './make-request';
import { fetchWithPromise } from './fetch-with-cache';

export async function inviteUser(email, organizationId, permissionTypeId) {
  const { data, errorMessage } = await makeRequest('post', 'auth/invite', {
    email,
    organizationId,
    permissionTypeId
  });
  return { data, errorMessage };
}

export async function checkInvite(token) {
  const key = `check-invite-${token}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'auth/check-invite', { token });
  if (data) {
    const { expired, needPassword } = data;
    return { expired, needPassword, errorMessage };
  }
  return { errorMessage };
}

export async function confirmInvite(token, firstName, lastName, password) {
  const { data, errorMessage } = await makeRequest('post', 'auth/confirm-invite', {
    token,
    firstName,
    lastName,
    password
  });
  if (data) {
    const { expired, needPassword } = data;
    return { expired, needPassword, errorMessage };
  }
  return { errorMessage };
}

export async function submitForgot(email) {
  const { data, errorMessage } = await makeRequest('post', 'auth/forgot', { email });
  return { data, errorMessage };
}

export async function submitReset(token, password) {
  const { data, errorMessage } = await makeRequest('post', 'auth/reset-password', { token, password });
  return { data, errorMessage };
}

export async function sendSignUp(email, password) {
  const { data, errorMessage } = await makeRequest('post', 'auth/sign-up', { email, password });
  return { data, errorMessage };
}

export async function sendRegister(dto) {
  const { data, errorMessage } = await makeRequest('post', 'auth/register', dto);
  return { data, errorMessage };
}

export async function sendRegisterWithCreditCard(dto) {
  const { data, errorMessage } = await makeRequest('post', 'auth/register-cc', dto);
  return { data, errorMessage };
}

const pendingVerify = {};
export async function sendSignUpVerify(token) {
  if (pendingVerify[token]) {
    return pendingVerify[token];
  }
  pendingVerify[token] = makeRequest('post', 'auth/sign-up-verify', { token });
  const { data, errorMessage } = await pendingVerify[token];
  delete pendingVerify[token];
  return { data, errorMessage };
}

export async function deleteSinglePermission(orgId, userId, permissionTypeId) {
  const { data, errorMessage } = await makeRequest(
    'delete',
    `auth/permission/org/${orgId}/user/${userId}/${permissionTypeId}`
  );
  return data || { errorMessage };
}
