import { DateTime } from 'luxon';

// export const currentTimeZone = DateTime.local().zoneName;
export const currentTimeZone = (org) => {
  return org?.timeZone || 'America/New_York';
};

export function formatDateYYYYMMDD(input, org) {
  let localDateTime;

  // Check if the input is a Date object
  if (input instanceof Date) {
    // Convert Date object to Luxon DateTime in the current time zone
    localDateTime = DateTime.fromJSDate(input, { zone: currentTimeZone(org) });
  }
  // Check if the input is a string (assuming ISO string)
  else if (typeof input === 'string') {
    // Parse ISO string to Luxon DateTime in the current time zone
    localDateTime = DateTime.fromISO(input, { zone: currentTimeZone(org) });
  } else {
    throw new Error('Invalid input: input must be a Date object or ISO string.');
  }

  // Format to 'YYYY-MM-DD' in the current time zone
  return localDateTime.toFormat('yyyy-MM-dd');
}
