import { createSlice } from '@reduxjs/toolkit';
import { fetchSettingsThunk } from './fetch-settings.thunk';

const settingsInitialState = {
  settings: []
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsInitialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSettingsThunk.fulfilled, (state, action) => {
      state.settings = action.payload;
    });
  }
});

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
