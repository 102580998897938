import { fetchWithPromise } from './fetch-with-cache';
import { makeRequest } from './make-request';

export async function getBillingContact(orgId) {
  const key = `get-billing-contact-${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'billing/contact', { orgId });
  return { contact: data, errorMessage };
}

const createPromises = {};
export async function createDefaultContact(orgId) {
  if (createPromises[orgId]) {
    return await createPromises[orgId];
  }
  createPromises[orgId] = makeRequest('post', 'billing/contact', { organizationId: orgId });
  const result = await createPromises[orgId];
  delete createPromises[orgId];
  return result;
}

const updatePromises = {};
export async function updateBillingContact(orgId, data) {
  if (updatePromises[orgId]) {
    return await updatePromises[orgId];
  }
  updatePromises[orgId] = makeRequest('put', `billing/contact/${orgId}`, data);
  const result = await updatePromises[orgId];
  delete updatePromises[orgId];
  return result;
}

let createPmPromise;
export async function createPaymentMethod(data) {
  if (createPmPromise) {
    return await createPmPromise;
  }
  createPmPromise = makeRequest('post', 'billing/payment-methods', data);
  const result = await createPmPromise;
  createPmPromise = undefined;
  return result;
}

export async function billingPaymentMethods(orgId) {
  const key = `get-pay-methods-${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'billing/payment-methods', { orgId });
  return { data, errorMessage };
}

export async function deleteBillingPaymentMethod(orgId, id) {
  const { data, errorMessage } = await makeRequest('delete', `billing/payment-methods/${orgId}/${id}`);
  return data || { errorMessage };
}

export async function billingPlanStatus(orgId) {
  const key = `get-billing-plan-status-${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'billing/plan-status', { orgId });
  return { data, errorMessage };
}

export async function getInvoicesList(orgId) {
  const key = `get-invoices-list-${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'billing/invoices', {
    orgId
  });
  return data ? { records: data } : { errorMessage };
}

export async function getInvoicePdfUrl(invoiceId) {
  const key = `get-pdf-${invoiceId}`;
  const { data, errorMessage } = await fetchWithPromise(key, `billing/pdf/invoice-id/${invoiceId}`);
  return { url: data, errorMessage };
}
