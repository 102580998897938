import { fetchWithPromise } from './fetch-with-cache';
import { makeRequest } from './make-request';

export async function getOrganizationContacts(orgId) {
  const { data, errorMessage } = await fetchWithPromise(`get-org-contacts-${orgId}`, `orgs/contacts/org/${orgId}`);
  return { contacts: data, errorMessage };
}

export async function createOrgContact(data) {
  return makeRequest('post', 'orgs/contacts', data);
}

export async function updateOrgContact(data, id) {
  return makeRequest('put', 'orgs/contacts', data, id);
}

export async function deleteOrgContact(contactId) {
  const { data, errorMessage } = await makeRequest('delete', 'orgs/contacts', undefined, contactId);
  return data || { errorMessage };
}
