import { makeRequest } from './make-request';
import { fetchWithPromise } from './fetch-with-cache';

export async function createUser(newData) {
  const { data, errorMessage } = await makeRequest('post', 'auth/users', newData);
  return { user: data?.user, errorMessage };
}

export async function deleteUser(userId) {
  const { data, errorMessage } = await makeRequest('delete', 'auth/users', undefined, userId);
  return data || { errorMessage };
}

export async function updateUser(update, id) {
  let { password, ...updateNoPwd } = update;
  if (!update.password) {
    update = updateNoPwd;
  }
  const { data, errorMessage } = await makeRequest('put', 'auth/users', update, id);
  return data || { errorMessage };
}

export async function getUsers(limit, name, page = 0, orderBy) {
  const key = `get-users-${limit},${page},${name},${orderBy}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'auth/users', { limit, page, name, orderBy });
  return { records: data?.users, totalCount: data?.totalCount, errorMessage };
}
