import { QuestionDialog } from './question-dialog.button';
import React from 'react';

export const AiAgentDisclaimerDialog = ({ aiDisclaimerOpen, onAiDisclaimerCancel, onAiDisclaimerOk }) => {
  return (
    <QuestionDialog
      dialogIsOpen={aiDisclaimerOpen}
      onDialogClose={onAiDisclaimerCancel}
      onDialogOk={onAiDisclaimerOk}
      dialogTitle="AI Agent Disclaimer"
      dialogText={
        <>
          <p className="mb-2 font-bold">
            Before enabling AI Agent features, please review and agree to the following terms:
          </p>
          <ul className="mb-2">
            <li>
              1. Cost: Each AI conversation incurs a charge of $0.50 if it includes at least one response from the
              initial opening message.
            </li>
            <li>
              2. Accuracy: While AI may strive to provide accurate and helpful responses, it may not always perfectly
              understand or address every inquiry. Human review & testing is recommended before enabling a task.
            </li>
            <li>
              3. Compliance: It is your responsibility to ensure that the use of AI agents complies with all applicable
              laws and regulations.
            </li>
          </ul>
          <p className="mb-2">
            By enabling this feature, you acknowledge and agree to these terms. If you have any questions or concerns,
            please contact our support team.
          </p>
          <p className="font-bold">Click Yes if you agree to these terms and would like to enable AI Agent features.</p>
        </>
      }></QuestionDialog>
  );
};
