import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  noLayout: false
};

export const noLayoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setNoLayout: (state, action) => {
      state.noLayout = action.payload;
    }
  }
});

export const { setNoLayout } = noLayoutSlice.actions;

export default noLayoutSlice.reducer;
