import { makeRequest } from './make-request';
import { fetchWithPromise } from './fetch-with-cache';

export async function newEmailTemplate(data) {
  return makeRequest('post', 'email-template', data);
}

export async function emailTemplatesForOrg(orgId) {
  const key = `email-templates-for-org-${orgId}`;
  const params = { orgId };
  const { data, errorMessage } = await fetchWithPromise(key, `email-template/for-org`, params);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function deleteEmailTemplate(id) {
  const { data, errorMessage } = await makeRequest('delete', 'email-template', undefined, id);
  return { data, errorMessage };
}

const updatePromises = {};
export async function updateEmailTemplate(id, data) {
  if (updatePromises[id]) {
    return await updatePromises[id];
  }
  updatePromises[id] = makeRequest('put', `email-template/${id}`, data);
  const result = await updatePromises[id];
  delete updatePromises[id];
  return result;
}
