import { fetchWithCache, fetchWithPromise } from './fetch-with-cache';

export async function getPermissionTypes() {
  const { data, errorMessage } = await fetchWithCache('get-perm-types', 'permissions/types');
  if (data) {
    data.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }
  return { types: data, errorMessage };
}

export async function getPermissions(userId) {
  const { data, errorMessage } = await fetchWithPromise('get-permissions', 'permissions', { userId });
  return { permissions: data, errorMessage };
}
